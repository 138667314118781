import * as TYPES from '../actions/types';

const defaultCurrencyCode = 'GBP';

const initialState = {
    isLoading: true,
    isMobileMenuActive: false,
    selectedCurrency: defaultCurrencyCode,
    currencies: [
        {
            code: 'GBP',
            symbol: '£',
            position: 'Left',
            space: false,
            name: 'Pound Sterling',
            locale: 'en-GB',
        },
    ],
    error: null,
};

export const toggleMobileMenu = (state) => {
    return {
        ...state,
        isMobileMenuActive: !state.isMobileMenuActive,
    };
};

export const closeMobileMenu = (state) => {
    return {
        ...state,
        isMobileMenuActive: false,
    };
};

const site = (state = initialState, action) => {
    const handlers = {
        [TYPES.SITE_TOGGLE_MOBILE_MENU]: toggleMobileMenu,
        [TYPES.SITE_CLOSE_MOBILE_MENU]: closeMobileMenu,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default site;
