export const address = [
    'Studio 2.01 Piano House',
    '9 Brighton Terrace',
    'Brixton',
    'SW9 8DJ',
    'United Kingdom',
];

export const contactEmail = 'hello@theholidaypeople.com';
export const displayTelephoneNumber = '0208 004 3975';
export const origin = 'https://theholidaypeople.com';
export const telephoneNumber = '+44-208-004-3975';
export const openingHours = [
    { dayOfWeek: 'Monday - Friday', timeRange: '9am - 7pm' },
    { dayOfWeek: 'Saturday', timeRange: '10am - 4pm' },
    { dayOfWeek: 'Sunday', timeRange: '10am - 4pm' },
];
export const primaryColour = '#fb2f58';

const constants = {
    address,
    contactEmail,
    displayTelephoneNumber,
    openingHours,
    primaryColour,
    telephoneNumber,
};

export default constants;
