import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const pageview = (url) => {
    window.gtag('config', publicRuntimeConfig.googleAnalyticsTrackingId, {
        page_path: url,
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};
