import React from 'react';
import Head from 'next/head';
import { origin, telephoneNumber } from '../../../utils/constants';

const data = {
    '@context': 'https://schema.org/',
    '@type': 'TravelAgency',
    '@id': origin,
    name: 'The Holiday People',
    url: origin,
    description:
        'The Holiday People offer package holidays and more. Find, compare and book your holiday with us!',
    logo: `${origin}/images/logos/the-holiday-people-narrow.png`,
    address: {
        '@type': 'PostalAddress',
        streetAddress: 'Piano House, 9 Brighton Terrace',
        addressLocality: 'Brixton',
        addressRegion: 'London',
        postalCode: 'SW9 8DJ',
        addressCountry: 'GB',
    },
    telephone: telephoneNumber,
    image: `${origin}/images/logos/the-holiday-people-narrow.png`,
};

function OrganisationStructuredData() {
    return (
        <Head>
            <script
                key="Organisation"
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
            ></script>
        </Head>
    );
}

OrganisationStructuredData.propTypes = {};

export default OrganisationStructuredData;
