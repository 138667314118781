import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useStore } from '../redux/store';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import Head from 'next/head';
import OrganisationStructuredData from '../components/structuredData/OrganisationStructuredData/OrganisationStructuredData';
import { pageview } from '../utils/analytics';
import '../styles/globals.scss';

const { publicRuntimeConfig } = getConfig();

function MyApp({ Component, pageProps }) {
    const store = useStore(pageProps.initialReduxState);
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <Provider store={store}>
            <Head>
                <meta name="theme-color" content="#fb2f58" key="theme" />
                <link rel="icon" href="/favicon.ico" key="favicon" />
                {/* Global Site Tag (gtag.js) - Google Analytics */}
                <script
                    key="ga"
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.googleAnalyticsTrackingId}`}
                />
                <script
                    key="gtag"
                    dangerouslySetInnerHTML={{
                        __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${publicRuntimeConfig.googleAnalyticsTrackingId}', {
                            page_path: window.location.pathname,
                            });
                        `,
                    }}
                />

                {/* Preconnects */}
                <link
                    rel="preconnect"
                    href="https://imgix.cosmicjs.com"
                    key="preCosmicImages"
                />
            </Head>
            <OrganisationStructuredData />
            <Component {...pageProps} />
            <script
                key="optinmonster"
                dangerouslySetInnerHTML={{
                    __html: `(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,119230,131374);`,
                }}
            />
        </Provider>
    );
}

export default MyApp;
